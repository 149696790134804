import React from "react";
import { BenefitsOfHiring, CaseStudies, Channels, FAQs, Features, Footer2, GetStarted, LandingBottom, MPVHomeIntro, OurStory, Partners, Pricing, Reviews, ServicesProvide, VmBenefitsOfHiring, VmGetStarted, VmsHomeIntro } from "src/Components";
import { VmsFaqs, getImageStructuralData, getPricingPlans } from "src/Constant";
import { OurReviews, faqs } from "src/Constant";
import { useDispatch } from "react-redux";
import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";

export default function MPVAssistantLanding() {
    const dispatch = useDispatch()

    const meta_title = "Hire A Virtual Medical Assistant | Save 60% - Start Now!"
    const meta_description = "Enhance your medical practice with our multi-talented Virtual Medical Assistants, skilled in admin tasks, billing, coding, scribing, social media, and online reputation management. Save over 60% on labor costs. Try our 7-day free trial for top-notch efficiency and support!"
    const meta_image = "https://honesttaskers.com/vms_image.png"

    const faqStructuralData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(item => {
          return {
          "@type": "Question",
          "name": item.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>"+ item.answer +"</p>"
            }
          }
        })
    }

    return (
        <div className="vms-home">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/multi-purpose-virtual-medical-assistant"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(faqStructuralData)}</script>
            </Helmet>
            <MPVHomeIntro onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <LandingBottom />
            <Partners />
            <ServicesProvide onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <BenefitsOfHiring />
            <Reviews Reviews={OurReviews}/>
            <OurStory />
            <CaseStudies />
            <Pricing plans={getPricingPlans('pricing-one')}/>
            <FAQs faqs={faqs}/>
            <VmGetStarted 
                title="Ready to enhance your medical practice and gain more time for yourself and family? Let HonestTaskers' Virtual Medical Assistants lead the way to efficiency and excellence."
                btnText="Schedule Discovery Call"
                onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            {/* <Channels /> */}
        </div>
    )
}