import React, { useEffect, useState } from "react";
import './style.scss';
import mapIMg from 'src/Assets/Images/Home/map.svg'
import contactHImg from 'src/Assets/Images/Home/ContactHImg.svg';
import iconPhone from 'src/Assets/Images/Home/IconPhone.png';
import iconLocation from 'src/Assets/Images/Home/IconLocation.png';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { submitContactAction } from "src/Redux/Actions/Contact";
import { toast } from "react-toastify";

export default function ContactUsPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [content, setContent] = useState('');

    const {
        submitContact_loading,
        submitContact_success
    } = useSelector(state => state.Contact)

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const formIsValid = name !== '' && email !== '' && phone !== '' && content != ''

    const submitForm = () => {
        if (!formIsValid) {
            toast("Please enter your information.", "error")
            return
        } 
        if (!email.match(validRegex))  {
            toast("Please, enter a valid email.", "error")
            return
        }
        showSpinner()
        const formdata = new FormData();
        formdata.append('name', name);
        formdata.append('email', email);
        formdata.append('phone', phone);
        formdata.append('content', content);
        dispatch(submitContactAction(formdata));
    }

    useEffect(() => {
        if (!submitContact_loading) {
            hideSpinner()
        }
    }, [dispatch, submitContact_loading, submitContact_success])

    return (
        <div className="contact-page">
           <div className="contact-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="bread-crumb">
                                <div className="item" onClick={() => navigate('/')}>Home</div>
                                <div className="seperator">{'>'}</div>
                                <div className="item active">Contact Us</div>
                            </div>
                            <h2>Contact Us</h2>
                        </div>
                        <div className="col-md-6 bread-img">
                            <img src={contactHImg}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-content">
               <div className="img-wrapper">
                    {/* <img src={mapIMg}/> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3415.113300552013!2d-97.3642861823192!3d32.72740563598974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e722d4d972de7%3A0x50a33620da8f9796!2sApple%20University%20Park%20Village!5e0!3m2!1sfr!2scm!4v1700563793599!5m2!1sfr!2scm" style={{ border:"0", width:"100%", height:"100%"}} allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
               </div>
               <div className="right-content">
                    <div className="info-row">
                        <img src={iconPhone}/>
                        <div>817 420-7608</div>
                    </div>
                    <div className="info-row">
                        <img src={iconLocation}/>
                        <div>1751 River Run Suite 200 Fort Worth, TX 76008</div>
                    </div>
                    <div className="form-wrapper mt-4">
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" className="form-control" placeholder="Enter your name" value={name} onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="form-group">
                            <label>E-mail</label>
                            <input type="text" className="form-control" placeholder="Enter your email" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input type="text" className="form-control" placeholder="Enter your phone" value={phone} onChange={e => setPhone(e.target.value)}/>
                        </div>
                        <div className="form-group">
                            <label>Message</label>
                            <textarea className="form-control" rows={5} placeholder="Enter your message" value={content} onChange={e => setContent(e.target.value)}></textarea>
                        </div>
                        <div className="btn btn-orange mt-4" onClick={() => submitForm()}>Send</div>
                    </div>
               </div>
            </div>
        </div>
    )
}