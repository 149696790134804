import React from "react";
import './style.scss';
import { Channels, FAQs, Features, Pricing, Reviews, VmBenefitsOfHiring, VmGetStarted, VmHowItWorks, VmsHomeIntro } from "src/Components";
import { VmsFaqs, faqs, getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
import { OurReviews } from "src/Constant";
import { useDispatch } from "react-redux";
import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";

export default function Home() {
    const dispatch = useDispatch()

    const howItWorkList = [
        {
            title: "Appointment Scheduling:",
            message: "Seamless and efficient."
        },
        {
            title: "Live Scribing During Consultation:",
            message: "Our certified medical scribes work in real-time for immediate documentation.:"
        },
        {
            title: "Review and Approval of Notes:",
            message: "Quickly review, edit, and approve notes post-consultation."
        },
        {
            title: "Seamless Integration with EMR Systems:",
            message: "Effortless transitions as our technology is compatible with most EMR systems."
        }
    ]

    const meta_title = "Hire Virtual Medical Scribe | Save 60% on Costs - Get Started"
    const meta_description = "Optimize your practice with HIPAA-compliant Virtual Medical Scribes. Our trained nurses and pharmacists ensure precise, real-time documentation, freeing you for patient care. No more late office hours!"
    const meta_image = "https://honesttaskers.com/vms_image.png"

    return (
        <div className="vms-home">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-medical-scribe"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(VmsFaqs))}</script>
            </Helmet>
            <VmsHomeIntro onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <VmBenefitsOfHiring />
            <VmHowItWorks howItWorkList={howItWorkList}/>
            <Features />
            <Reviews className="white-bg" Reviews={OurReviews}/>
            <FAQs faqs={VmsFaqs}/>
            <Pricing plans={getPricingPlans('pricing-two')}/>
            <VmGetStarted onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            {/* <Channels /> */}
        </div>
    )
}