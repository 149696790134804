import caseArrowLeft from 'src/Assets/Images/Home/CaseStudyNavLeft.png';
import caseArrowRight from 'src/Assets/Images/Home/CaseStudyNavRight.png';
import social1 from 'src/Assets/Images/Home/Social1.svg';
import social2 from 'src/Assets/Images/Home/Social2.svg';
import social3 from 'src/Assets/Images/Home/Social3.svg';
import social4 from 'src/Assets/Images/Home/Social4.svg';
import social5 from 'src/Assets/Images/Home/Social5.svg';
import storyImg1 from 'src/Assets/Images/Home/ContactHImg.svg';
import storyImg2 from 'src/Assets/Images/Story/Story2.svg';
import storyImg3 from 'src/Assets/Images/Story/Story6.svg';
import storyImg4 from 'src/Assets/Images/Home/FaqHImg.svg';
import storyImg5 from 'src/Assets/Images/Home/WhyHImg.svg';
import policy1 from 'src/Assets/Images/Policy/P1.svg';
import policy2 from 'src/Assets/Images/Policy/P2.svg';
import policy3 from 'src/Assets/Images/Policy/P3.svg';
import policy4 from 'src/Assets/Images/Policy/P4.svg';
import policy5 from 'src/Assets/Images/Policy/P5.svg';
import policy6 from 'src/Assets/Images/Policy/P6.png';
import policy7 from 'src/Assets/Images/Policy/P7.svg';
import policy8 from 'src/Assets/Images/Policy/P8.svg';
import policy9 from 'src/Assets/Images/Policy/P9.svg';
import policy10 from 'src/Assets/Images/Policy/P10.svg';
import policy11 from 'src/Assets/Images/Policy/P11.svg';
import service1 from 'src/Assets/Images/Services/Services1.svg';
import service2 from 'src/Assets/Images/Services/Services2.svg';
import service3 from 'src/Assets/Images/Services/Services3.svg';
import service4 from 'src/Assets/Images/Services/Services4.svg';
import service5 from 'src/Assets/Images/Services/Services5.svg';
import vision1 from 'src/Assets/Images/Vision/Vision1.svg';
import vision2 from 'src/Assets/Images/Vision/Vision2.svg';
import customerImg1 from 'src/Assets/Images/Home/Customer-1.png';
import customerImg2 from 'src/Assets/Images/Home/Customer-2.png';
import customerImg3 from 'src/Assets/Images/Home/Customer-3.png';
import customerImg4 from 'src/Assets/Images/Home/Customer-4.png';
import customerImg5 from 'src/Assets/Images/Home/Customer-5.png';
import dutyImg1 from 'src/Assets/Images/VmrHome/Duty1.svg';
import dutyImg2 from 'src/Assets/Images/VmrHome/Duty2.svg';
import dutyImg3 from 'src/Assets/Images/VmrHome/Duty3.svg';
import dutyImg4 from 'src/Assets/Images/VmrHome/Duty4.svg';
import arrowLeft1 from 'src/Assets/Images/Profile/ArrowCircleLeft.png';
import arrowRight1 from 'src/Assets/Images/Profile/ArrowCircleRight.png';

export const CaseStudyArrowLeft = `
    <div style='width:72px;height:72px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${caseArrowLeft}' alt='nav-arrow' />
    </div>
`;
export const CaseStudyArrowRight = `
    <div style='width:72px;height:72px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${caseArrowRight}' alt='nav-arrow' />
    </div>
`;

export const ArrowLeftOne = `
    <div style='width:44px;height:44px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowLeft1}' alt='nav-arrow' />
    </div>
`;
export const ArrowRightOne = `
    <div style='width:44px;height:44px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowRight1}' alt='nav-arrow' />
    </div>
`;

export const getPricingPlans = (page = '', onlyCustom = false, hideCustom = false) => {
    var partTime = { hours: "20hrs per week (4 Hours/Day)", cost: "$12/hr" }
    var fullTime = { hours: "40hrs per week (8 Hours/Day)", cost: "$10/hr" }
    var customTime = { hours: "custom", cost: "custom" }
    var parTimeTitle = "PART-TIME OPTIONS"
    var fullTimeTitle = "FULL-TIME OPTIONS"
    var customTitle = "CUSTOM PRICING OPTIONS"
    var options = [
        "Hourly Payment Structure",
        "Billed Weekly, Every Monday",
        "Your Personal Account Liaison",
        "Transparent Flat-Fee, No Hidden Overtime Charges",
        "Zero Contractual Obligations & No Initial Fees",
        "Freedom to Terminate Anytime",
        "Select Your Preferred Assistant",
        "View Time Logs & Activity Monitoring",
        "Consistency with the Same Assistant Daily"
    ]

    if (page === 'scribe') {
        partTime = { ...partTime, cost: "$12/hr" }
        fullTime = { ...fullTime, cost: "$10/hr" }
    } 

    if (page === 'pricing-one') {
        partTime = { ...partTime, cost: "+$2 on full time rates" }
        fullTime = { ...fullTime, cost: "Rates starts at $10.00/hr" }
    }

    if (page === 'pricing-two') {
        partTime = { ...partTime, cost: "+$2 on full time rates" }
        fullTime = { ...fullTime, cost: "Rates starts at $10.65/hr" }
    }

    if (page === 'contact') {
        partTime = { ...partTime, cost: "Contact us for pricing" }
        fullTime = { ...fullTime, cost: "Contact us for pricing" }
    }

    if (page === 'no-custom') {
        partTime = { ...partTime, cost: "Contact us for pricing", hours: "20hrs/wk" }
        fullTime = { ...fullTime, cost: "Contact us for pricing", hours: "40hrs/wk" }
        // customTime = { ...customTime, cost: "$5,000/month", hours: "Tailored for large health institutions, catering to unlimited calls." }
    }

    if (page === 'rp-monitoring') {
        partTime = { ...partTime, cost: "Contact Sale"}
        fullTime = { ...fullTime, cost: "Contact Sale"}
        customTime = { ...customTime, cost: "Contact Sale"}
    }

    if (page === 'triage') {
        partTime = { ...partTime, cost: "Contact us for pricing", hours: "20hrs/wk" }
        fullTime = { ...fullTime, cost: "Contact us for pricing", hours: "40hrs/wk" }
        // customTime = { ...customTime, cost: "$5,000/month", hours: "Tailored for large health institutions, catering to unlimited calls." }
    }

    if (hideCustom) {
        return [
            {
                title: parTimeTitle,
                hours: partTime.hours,
                cost: partTime.cost,
                options: options
            },
            {
                title: fullTimeTitle,
                hours: fullTime.hours,
                cost: fullTime.cost,
                options: options
            }
        ]
    }

    if (onlyCustom) {
        return [
            {
                title: customTitle,
                hours: customTime.hours,
                cost: customTime.cost,
                options: options
            }
        ]
    }

    return [
        {
            title: parTimeTitle,
            hours: partTime.hours,
            cost: partTime.cost,
            options: options
        },
        {
            title: fullTimeTitle,
            hours: fullTime.hours,
            cost: fullTime.cost,
            options: options
        },
        {
            title: customTitle,
            hours: customTime.hours,
            cost: customTime.cost,
            options: options
        }
    ]
}

export const faqs = [
    {
        question: "What is HonestTaskers?",
        answer: "HonestTaskers is a specialized platform offering trained and certified multi-skilled virtual medical assistants to healthcare professionals, aiming to alleviate administrative burdens and enhance practice efficiency."
    },
    {
        question: "Are your assistants trained specifically for medical tasks?",
        answer: "Absolutely! Our virtual medical assistants undergo rigorous training and certification, ensuring they are well-versed with medical terminologies, protocols, and the specific needs of healthcare practices."
    },
    {
        question: "Is my patient data safe with your virtual assistants?",
        answer: "Yes, data security is paramount to us. Our virtual medical assistants adhere strictly to HIPAA guidelines, ensuring patient confidentiality and data protection at all times."
    },
    {
        question: "Do I need to sign a long-term contract?",
        answer: "No, there are no binding contracts. We value flexibility, so you have the freedom to terminate or adjust services as per your needs."
    },
    {
        question: "How do I communicate with my virtual medical assistant?",
        answer: "Our platform provides seamless communication channels. You can interact with your assistant via emails, phone calls, or any preferred communication tool."
    },
    {
        question: "How do I get started?",
        answer: "Simply reach out to us through our website or contact number. Our team will guide you through the setup process, ensuring a smooth onboarding experience."
    },
    {
        question: "What sets HonestTaskers apart from other virtual assistant platforms?",
        answer: "HonestTaskers is the brainchild of a unique blend of educational and medical professionals. Our deep-rooted understanding of medical practice challenges, combined with our dedication to delivering joy and our multi-skilled, culturally aligned assistants, sets us apart in the industry."
    },
    {
        question: "Are your virtual medical assistants trained in specialized skills?",
        answer: "Yes, beyond their formal education, our virtual medical assistants undergo rigorous training and attain certifications in specialized areas such as scribing, medical administration, billing, and even in realms like social media marketing pertinent to healthcare practices."
    },
    {
        question: "Can I request details of my virtual assistant's qualifications?",
        answer: "Absolutely! We believe in transparency and are happy to provide details regarding the educational background and certifications of the virtual medical assistant assigned to you."
    },
    {
        question: "Which software or platforms are optimal for collaboration with an Honesttaskers Virtual Assistant (HVA)?",
        answer: 'While our HVAs can adapt to your current office software, we endorse certain software for their robust security and functionality:<ul class="list-style-disc">' + 
        '<li>Health Records: Practice Fusion EMR</li>' +
        '<li>Video Conferencing: Secure platforms like Zoom or Google Meet with G Suite</li>' +
        '<li>Cloud Storage: Google Drive with G Suite (HIPAA-compliant)</li>' +
        '<li>Fax Management: Updox</li>' + 
        '<li>Phone Systems: RingCentral for VOIP</li>' +
        '</ul>'
    },
    {
        question: "Can you elaborate on HIPAA adherence and the safeguarding of my health data?",
        answer: "Ensuring HIPAA compliant procedures remains the onus of the medical practitioner. However, our virtual medical assistants are well-equipped, having undergone HIPAA training and are also governed by the Philippines’ Data Privacy Act of 2012. We recommend an additional safeguard where our clients have individual HVAs sign a Business Associate Agreement (BAA). While our corporation doesn't access any client's PHI, we ensure that your collaboration with our HVAs remains protected. The two recommended methods to provide your HVA access to your EMR are: a direct username + password with restricted system access, or through a computer in your practice equipped with remote desktop software, granting exclusive access to the HVA. If any intentional PHI breach occurs overseas, the Data Privacy Act of 2012 in the Philippines acts as your shield, ensuring accountability and redressal."
    },
    {
        question: "What services do your virtual medical assistants provide?",
        answer: "Our virtual medical assistants are trained in a variety of areas, including medical transcription & scribing, administrative support, medical billing, digital marketing, and online reputation management. They can also offer customized solutions tailored to a specific practice's needs."
    },
    {
        question: "How do you ensure the reliability of your assistants?",
        answer: "We have a stringent selection process that prioritizes not only skills but also dedication and a cultural fit. Our aim is to ensure long-term commitment and reliability from every assistant."
    },
    {
        question: "How are your services billed?",
        answer: "We offer transparent flat-rate billing with no hidden charges. You'll be billed weekly, every Monday, with the freedom to scale services based on your requirements."
    },
    {
        question: "Can I choose or change the assistant I work with?",
        answer: "Yes, you have the liberty to select your preferred assistant. If, for any reason, you wish to change, we will facilitate that for you to ensure a perfect fit."
    },
    {
        question: "What if I have a specialized task not listed in your services?",
        answer: "We understand that every practice might have unique needs. Discuss your specific requirements with us, and we will endeavor to provide a tailored solution or train our assistants accordingly."
    },
    {
        question: "Can I get a trial or a demonstration of your services?",
        answer: "Absolutely! We believe in our service quality and are happy to provide a demonstration or trial period. Reach out to our team to discuss the details."
    },
    {
        question: "What are the educational qualifications of your virtual medical assistants?",
        answer: "A significant majority, 95% to be precise, of our virtual medical assistants hold either a bachelor's or an associate degree in nursing. Their solid educational foundation in the medical field ensures a deep understanding of the nuances and intricacies of healthcare."
    },
    {
        question: "How do you ensure that the training of your assistants is up-to-date with current medical practices?",
        answer: "We place a strong emphasis on continuous learning. Our assistants regularly undergo refresher courses and are updated with the latest in medical protocols, technologies, and best practices to ensure they deliver top-notch, relevant services to our clients."
    },
    {
        question: "How will partnering with Honesttaskers benefit my financials?",
        answer: "Think of it in terms of both time and money. For instance, if you consult with 20 patients daily and dedicate two minutes charting for each, that’s 40 minutes daily. With a live virtual assistant managing your charts in real-time, you could reallocate those 40 minutes to consult two additional patients. At an average revenue of $70 per patient, that’s an added $150 daily, amounting to approximately $3,000 monthly. More than just a saving, it’s an earning!"
    },
    {
        question: "In the event of an intentional PHI breach in the Philippines, how am I safeguarded?",
        answer: "The Data Privacy Act of 2012, enacted by the Philippine government, parallels the US's HIPAA. It provides stringent measures against intentional breaches of sensitive data, like PHI, ensuring accountability even when collaborating internationally. Our healthcare virtual assistants, based in the Philippines, are thoroughly vetted, background checked, and have undergone HIPAA training. Furthermore, they are required to sign a Business Associate Agreement, emphasizing the sanctity of PHI protection."
    }
]

export const footerSocial = [
    {
        icon: social1,
        url: "https://www.instagram.com/honesttaskers/"
    },
    {
        icon: social2,
        url: "https://www.facebook.com/profile.php?id=61552599561347"
    },
    {
        icon: social3,
        url: "https://www.tiktok.com/@honest.taskers"
    },
    // {
    //     icon: social4,
    //     url: "https://www.linkedin.com/in/honest-taskers-04607229a/"
    // },
    {
        icon: social5,
        url: "https://twitter.com/HonestTaskers"
    },
]

export const StoriesList =  [
    {
        title: "The Genesis",
        message: "Meet Roland Omene, an educational entrepreneur with ventures like FrogTutoring and GradeGetter under his belt, and Dr. Zian Omene, the dedicated mind behind Shiloh Health, a unique integrative psychiatric practice. Together, they embarked on a journey that culminated in HonestTaskers.",
        img: storyImg5,
        list: []
    },
    {
        title: "A Challenge Recognized",
        message: "Dr. Zian’s solo practice came with challenges. Juggling diverse roles - from clinical scribing to administrative functions, and from billing procedures to digital marketing - she found herself stretched too thin. The lines between professional duties and personal life blurred, with work seeping into cherished family moments.",
        img: storyImg4,
        list: []
    },
    {
        title: "First Attempts at a Solution",
        message: "To redistribute this load, they ventured into hiring virtual assistants. But this solution soon presented its own set of problems:",
        img: storyImg3,
        list: [
            "A distressing turnover rate, with three assistants leaving within a mere three months.",
            "While some assistants had expertise in specific areas like medical scribing, they lacked skills in others. This inconsistency meant continually seeking additional help."
        ]
    },
    {
        title: "A Broader Perspective",
        message: "Recognizing that this wasn’t just a personal challenge but a widespread issue, Roland saw an opportunity. He envisioned a solution not just for Dr. Zian, but for countless medical professionals facing similar struggles. Crafting the HonestTaskers Vision: Blending Roland’s background in systematic training with Dr. Zian’s medical insights, they sought to create a holistic solution:",
        img: storyImg2,
        list: [
            "Recruitment pivoted towards assistants with a foundation in medical knowledge but also aligned with the core values of dedication and professionalism.",
            "A rigorous training program was established, certifying assistants in areas such as:",
            "Comprehensive Medical Transcription Efficient Administrative Protocols Streamlined Medical Billing Techniques Modern Digital Marketing & Reputation Management"
        ]
    },
    {
        title: "Our HonestTaskers Promise",
        message: "It’s not just about skill proficiency; it's about dedication and alignment with a greater purpose. Every assistant at HonestTaskers is vetted not just for their professional expertise but for their cultural fit and commitment to adding tangible value to medical practices.",
        img: storyImg1,
        list: []
    }
]

export const WhyUsList = [
    {
        title: "Deep-Rooted Understanding",
        message: "Founded by a unique blend of an educational entrepreneur and a medical professional, HonestTaskers isn’t just another virtual assistance platform. We have firsthand experience with the challenges medical professionals face, giving us an unparalleled understanding of your needs.",
    },
    {
        title: "All-Rounder Virtual Assistants",
        message: "Where many virtual assistant platforms offer jack-of-all-trades (and often masters of none), HonestTaskers trains assistants to be versatile specifically within the medical domain. From medical transcription and billing to modern digital marketing, our team members are specialized multi-taskers."
    },
    {
        title: "Stability & Reliability",
        message: "The frequent turnover of virtual assistants is a common concern in the industry. With HonestTaskers, we've addressed this pain point. Our rigorous selection process, combined with a culture-first approach, ensures that the assistants are not just qualified but are also committed long-term."
    },
    {
        title: "Rigorous Training & Continuous Learning",
        message: "Our background in education has equipped us to provide detailed, practical, certified training to our assistants. But the learning doesn't stop there; we emphasize continuous education, ensuring that our team is always updated with the latest in medical protocols and technologies."
    },
    {
        title: "Cultural Fit",
        message: "At HonestTaskers, we believe that skills can be taught, but attitude and dedication are intrinsic. We prioritize hiring assistants who resonate with our core values and ethos, ensuring a seamless integration with your practice."
    },
    {
        title: "Dedicated to Your Success",
        message: "HonestTaskers doesn't view our service as a mere transaction. Each partnership is a commitment to the mutual growth and success of both parties. Our assistants are trained to be proactive, always seeking ways to add value and improve the practice they support.",
    },
    {
        title: "HIPAA Compliance:",
        message: "In the medical world, patient confidentiality and data security are paramount. Our rigorous adherence to HIPAA guidelines means you can trust us with your data, and more importantly, your patients can trust you."
    },
    {
        title: "Affordability & Flexibility: ",
        message: "With transparent flat fees, no hidden charges, and the freedom to scale services based on demand, HonestTaskers provides both cost efficiency and operational flexibility."
    },
    {
        title: "Online Reputation Management:",
        message: "In today's digital age, your online reputation can make or break your practice. Our assistants are trained in online reputation management, ensuring that your practice shines in the best light across all platforms."
    },
    {
        title: "Personal Touch:",
        message: "Our founders, Roland and Dr. Zian, have instilled a personal touch in HonestTaskers. Beyond professional efficiency, we value and foster human connections. With us, you're not just another client; you're a valued partner."
    },
    {
        title: "In Conclusion:",
        message: "HonestTaskers is more than just a solution; it's a revolution in medical virtual assistance. In choosing us, you're not merely outsourcing tasks, you're integrating a dedicated partner committed to elevating your practice to new heights."
    }
]

export const PrivacyPolicyList = [
    {
        title: "Introduction",
        message: "Welcome to HonestTaskers, your trusted virtual medical assistant platform. We prioritize the safeguarding of your personal and medical information. If you have queries or concerns regarding our policy or our practices concerning your data, please contact us at [Insert Contact Email].",
        img: policy1,
        list: []
    },
    {
        title: "Information Collection",
        message: "We collect data that you voluntarily provide, such as personal identification details, medical histories, diagnostic information, and other medical-related data. Additionally, we collect standard data like address, contact details, and payment information.",
        img: policy2,
        list: []
    },
    {
        title: "Purpose of Data Utilization",
        message: "The information collected via our platform serves multiple purposes:",
        img: policy3,
        list: [
            "To facilitate account creation and the login process",
            "To send administrative and medical information to you",
            "To process and manage medical assistance requests",
            "To convey diagnostic or medical information, with your consent"
        ]
    },
    {
        title: "Data Sharing Protocols",
        message: "Your data is confidential. However, certain situations necessitate information sharing:",
        img: policy4,
        list: [
            "Compliance with Medical and Legal Regulations: We may disclose your data where mandated by medical ethics or legal directives.",
            "Vital Medical Interests: In life-threatening situations where immediate medical intervention is essential.",
            "With your Explicit Consent."
        ]
    },
    {
        title: "Data Retention Period",
        message: "Your personal and medical data is retained only for durations essential for the purposes mentioned in this policy or as long recognized or mandated by medical regulations and laws.",
        img: policy5,
        list: []
    },
    {
        title: "Data Security Measures",
        message: "The sensitivity of medical data demands robust protection. We utilize advanced organizational and technical measures, such as encryption, secure servers, and strict access controls, to shield your data.",
        img: policy6,
        list: []
    },
    {
        title: "Minors' Data",
        message: "We are committed to the protection of children and do not knowingly collect data from or market to individuals under 18 years without parental or guardian consent.",
        img: policy7,
        list: []
    },
    {
        title: "Your Rights and Data Access",
        message: "You hold the right to review, modify, or delete your account and associated data. For individuals in regions with stringent data protection regulations, you also possess the right to challenge or question our data processing actions.",
        img: policy8,
        list: []
    },
    {
        title: "Periodic Policy Updates",
        message: "This privacy policy may undergo periodic reviews and revisions. Any modifications will be signposted with an updated “Revised” date.",
        img: policy9,
        list: []
    },
    {
        title: "Queries Regarding This Policy",
        message: "For any questions or suggestions regarding this policy, kindly reach out to us at [Insert Contact Email] or via post to: [Insert Company Address",
        img: policy10,
        list: []
    },
    {
        title: "HIPAA Compliance Assurance",
        message: "As a platform operating in the medical realm, we are fully compliant with the Health Insurance Portability and Accountability Act (HIPAA). We adopt rigorous measures to ensure the confidentiality, integrity, and availability of all electronic protected health information (ePHI) we handle.",
        img: policy11,
        list: []
    }
]

export const services = [
    {
        img: service5,
        title: "Medical Transcription & Scribing:",
        message: "Rely on our multi-talented virtual medical assistants for impeccable medical transcription services. Trained meticulously in medical terminologies, they guarantee clarity and precision with every transcription.",
        benefits: [
            "Swift and precise documentation",
            "Accurate capture of patient details",
            "Adherence to medical documentation best practices"
        ]
    },
    {
        img: service4,
        title: "Administrative Support:",
        message: "From scheduling to record-keeping, our assistants offer an all-encompassing administrative support system, ensuring smooth operations for your medical practice.",
        benefits: [
            "Systematic patient record management",
            "Coordinated appointment schedules and reminders",
            "Enhanced patient-provider liaison"
        ]
    },
    {
        img: service3,
        title: "Medical Billing:",
        message: "Traverse the complexities of medical billing with ease. Our multi-skilled virtual assistants ensure accurate, prompt billing processes, substantially reducing discrepancies and ensuring timely reimbursements.",
        benefits: [
            "Precise billing, minimizing disputes",
            "Prompt bill submissions for steady cash flow",
            "Comprehensive knowledge of evolving billing codes and norms"
        ]
    },
    {
        img: service2,
        title: "Digital Marketing & Online Reputation Management:",
        message: "In an interconnected digital world, your online persona is pivotal. Our virtual medical assistants aren't just administratively adept; they're also proficient in fortifying your online presence and adeptly managing your digital reputation.",
        benefits: [
            "Amplified online visibility to magnetize potential patients",
            "Stalwart reputation management across platforms",
            "Persistent online patient engagement strategies"
        ]
    },
    {
        img: service1,
        title: "Customized Solutions:",
        message: "Description: Recognizing the unique intricacies of each medical practice, we're equi pped to mold our services, offering tailor-made solutions that sync seamlessly with your practice's distinct demands.",
        benefits: [
            "Customized assistance attuned to your practice",
            "Scalable service modules to pivot as per evolving needs",
            "Focused support for niche tasks"
        ]
    }
]

export const TermsOfServiceList = [
    {
        title: "Introduction",
        message: 'Thank you for choosing HonestTaskers, a premier virtual medical assistant platform. These Terms of Service ("Terms") govern your use of our website, services, and products. By accessing or using HonestTaskers, you acknowledge that you have read, understood, and agree to these Terms.',
        list: []
    },
    {
        title: "Registration and Account Security",
        message: "Users may be required to register to access certain features. You agree to:",
        list: [
            "Provide accurate, up-to-date information.",
            "Safeguard your username and password.",
            "Notify us immediately of unauthorized account use."
        ]
    },
    {
        title: "Privacy",
        message: "Your use of HonestTaskers is also governed by our Privacy Policy, which details our practices concerning the use and storage of user data.",
        list: []
    },
    {
        title: "Use of Services",
        message: 'While we strive for accuracy and reliability, our services are provided "as is" without any warranty. Users are responsible for verifying any medical advice or information before acting on it.',
        list: []
    },
    {
        title: "Termination",
        message: 'HonestTaskers reserves the right to suspend or terminate your access to our services at our sole discretion without notice for conduct that violates these Terms or is harmful to other users, us, or third parties, or for any other reason.',
        list: []
    },
    {
        title: "Limitation of Liability",
        message: 'To the maximum extent permitted by law, HonestTaskers shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.',
        list: []
    },
    {
        title: "Medical Disclaimer",
        message: "HonestTaskers is a platform that provides virtual medical assistance but does not replace professional medical advice, diagnosis, or treatment. Always seek advice from a qualified healthcare provider about any medical concern.",
        list: []
    },
    {
        title: "Intellectual Property",
        message: 'All content, trademarks, services marks, trade names, logos, and icons are proprietary to HonestTaskers. Nothing contained on the website should be construed as granting any license or right to use any trademark displayed on this website without the written permission from HonestTaskers.',
        list: []
    },
    {
        title: "User Content",
        message: 'Any content you upload or share on HonestTaskers remains your property. However, by posting content, you grant us a license to use, copy, reproduce, process, adapt, and display the content.',
        list: []
    },
    {
        title: "Changes to These Terms",
        message: 'HonestTaskers reserves the right to modify these Terms at any time. It is your responsibility to review the Terms regularly.',
        list: []
    },
    {
        title: "Governing Law",
        message: 'These Terms and any disputes arising out of it shall be governed by the laws of Texas.',
        list: []
    },
    {
        title: "Contact Us",
        message: 'For any questions regarding these Terms, please contact us at info@honesttaskers.com or: 1751 River Run #200 Fort Worth, TX 76107',
        list: []
    }
]

export const visionServiceList = [
    {
        title: "A Beacon for Virtual Medical Assistants:",
        img: vision2,
        message: "At HonestTaskers, our ambition extends beyond the norm. We envision a workplace that stands as the epitome of excellence in the virtual realm. Through a combination of professional growth opportunities, nurturing environments, and a sense of belonging, we aim to make HonestTaskers the most coveted platform for virtual medical assistants.",
        benefits: []
    },
    {
        title: "Delivering Joy as a Service:",
        img: vision1,
        message: "Our mission transcends basic service provision. Instead, we aim for an emotional resonance, seeking to bring genuine delight and relief to the healthcare professionals we assist. This joy stems from a holistic approach, covering administrative intricacies to broader medical challenges, ensuring every touchpoint adds value and lightens the load for our clients.",
        benefits: []
    }
]

export const CoreValues = [
    {
        img: policy11,
        title: "Be Humble:",
        list: [],
        message: "Humility is our cornerstone. We recognize that every individual, regardless of their role, brings immense value. It's this humility that allows us to continually learn, adapt, and refine our services for the better."
    },
    {
        img: policy11,
        title: "Be Passionate and Determined:",
        list: [],
        message: "Our zeal and tenacity propel us forward. Every task, no matter how minute, is approached with fervor and a commitment to excellence.",
    },
    {
        img: policy11,
        title: "Build Positive Team and Family Spirit:",
        list: [],
        message: "We believe that a cohesive and harmonious team is the backbone of any successful endeavor. By fostering mutual respect, encouragement, and camaraderie, we ensure that our virtual platform thrives as a familial community."
    },
    {
        img: policy11,
        title: "Pursue Growth and Learning:",
        list: [],
        message: "The medical landscape is ever-evolving, and so are we. By championing continuous professional development and personal growth, we ensure that our team is always equipped with the latest knowledge and skills."
    },
    {
        img: policy11,
        title: "Deliver Joy through Service:",
        list: [],
        message: "Our engagements go beyond transactions. By deeply understanding our clients' needs, being proactive, and consistently delivering excellence, we ensure that every interaction is imbued with joy."
    },
    {
        img: policy11,
        title: "Be Your Brother and Sister's Keeper:",
        list: [],
        message: "Solidarity and mutual support are integral to our ethos. We stand by each other, offering assistance, understanding, and empathy, ensuring that no one feels isolated or overwhelmed."
    }
]

export const VmsFaqs = [
    {
        question: "How quickly can I integrate a virtual scribe into my practice?",
        answer: "Our seamless onboarding ensures you can integrate a certified scribe swiftly, often within just a few days."
    },
    {
        question: "What about the security of virtual scribing?",
        answer: " Data security is paramount at HonestTaskers. We guarantee full HIPAA compliance and adherence to the Data Privacy Act of 2012."
    },
    {
        question: "How do Virtual Medical Scribes ensure accuracy in documentation?",
        answer: "Our Virtual Medical Scribes are rigorously trained and come from backgrounds as registered nurses or pharmacists, primarily from the Philippines. They have completed over 80 hours of intensive instructional training and are certified, ensuring high precision in medical scribing. Their expertise in healthcare protocols and EHR management guarantees accuracy in documenting patient encounters."
    },
    {
        question: "Can Virtual Medical Scribes adapt to different EHR systems?",
        answer: "Yes, our Virtual Medical Scribes are skilled in a variety of EHR systems. They receive specialized training, including the strategic use of AI in healthcare, enabling them to adapt quickly to your specific EHR system and workflow."
    },
    {
        question: "What measures are in place to ensure patient confidentiality with Virtual Medical Scribes?",
        answer: "Patient confidentiality is a top priority. Our Virtual Medical Scribes adhere to strict HIPAA compliance guidelines. They undergo rigorous training in data privacy and security to ensure all patient information is handled with the utmost care and discretion."
    },
    {
        question: "How does the use of Virtual Medical Scribes impact the workflow of my practice?",
        answer: "The integration of a Virtual Medical Scribe into your practice is designed to streamline your workflow significantly. They take on the responsibility of documentation, allowing you to focus more on patient care. This can lead to more efficient patient visits and reduced administrative burden."
    },
    {
        question: "Are there options for a trial period to assess the compatibility of a Virtual Medical Scribe with my practice?",
        answer: "Yes, we understand the importance of ensuring a good fit for your practice. Therefore, we offer one-week placement trials for our Virtual Medical Scribes. This trial period allows you to assess their compatibility with your practice's needs without any long-term commitment."
    }
]

export const OurReviews = [
    {
        id: 1,
        title: "Brightview Psychiatry",
        name: "Dr. Lucy Rodriguez",
        message: "HonestTaskers has been a game-changer for our practice. With their Virtual Medical Assistant handling documentation, I now have more time to connect with my patients. I can't imagine running my clinic without them now!",
        image: customerImg4
    },
    {
        id: 2,
        title: "ClearMind Psychiatry",
        name: "Dr. Ethan Moore",
        message: "I began my practice with a deep focus on providing unparalleled psychiatric care. However, the burden of administrative tasks became overwhelming. HonestTaskers has relieved me of those burdens and impressively enhanced our online visibility. Their contribution is invaluable!",
        image: customerImg1
    },
    {
        id: 3,
        title: "New Beginnings Mental Health Center",
        name: "Dr. Marcus Johnson",
        message: "I was initially unsure about the efficacy of a virtual assistant, especially in the sensitive field of mental health. But HonestTaskers has shown me the way. Their team's expertise in medical scribing and online reputation management has been indispensable.",
        image: customerImg2
    },
    {
        id: 4,
        title: "Serenity Psychological",
        name: "Sandra Kim",
        message: "I'm amazed at the breadth of skills the HonestTaskers' VMAs possess. From reducing our billing errors to boosting our clinic's social media engagement, they've truly revolutionized our operations. It feels like they are genuinely invested in our clinic's success.",
        image: customerImg3
    }
];

export const ReceptionReviews = [
    {
        id: 5,
        title: "Williams Psychiatry",
        name: "Dr. Richard Williams",
        message: "HonestTaskers’ receptionist service transformed our patient interaction. Their warmth and professionalism feel as if they're right here with us in the clinic.",
        image: customerImg5
    },
    ...OurReviews
]

export const ReceptionFaqs = [
    {
        question: "How quickly can I onboard a virtual medical receptionist?",
        answer: "Our streamlined process ensures a virtual receptionist can be integrated into your practice within a matter of days."
    },
    {
        question: "How do your receptionists maintain their professional standards?",
        answer: "Apart from our rigorous in-house training, our virtual receptionists have taken specialized courses from platforms like Udemy and Coursera on customer service excellence and professional receptionist skills to ensure they stay updated with industry best practices."
    },
    {
        question: "What measures are taken for data security and patient information?",
        answer: "Patient data protection is our top priority. Our virtual receptionists adhere strictly to HIPAA guidelines, ensuring utmost security and confidentiality."
    },
    {
        question: "Can the virtual receptionists handle high call volumes during peak hours?",
        answer: "Absolutely! Our virtual medical receptionists are trained to handle high call volumes efficiently, ensuring each patient receives prompt and courteous service."
    },
    {
        question: "What if we need to provide specific training to align the receptionist with our practice's unique needs?",
        answer: "We understand every practice is unique. Our virtual receptionists are adaptable and are more than willing to undergo any specific training you deem necessary to align with your practice's standards and procedures."
    },
    {
        question: "How are your services billed?",
        answer: "We have a stringent selection process that prioritizes not only skills but also dedication and a cultural fit. Our aim is to ensure long-term commitment and reliability from every assistant."
    },
    {
        question: "Are there any additional costs or hidden fees associated with the service?",
        answer: "We believe in transparency. The pricing and plans are straightforward with no hidden fees. Any additional services or customizations will be discussed and agreed upon upfront."
    }
]

export const VmrBenefitsList = [
    {
        title: "Enhanced Patient Experience:",
        message: "Our virtual receptionists are trained to provide warm, empathetic, and efficient patient interactions from the first call, ensuring satisfaction and trust.",
        color: "green"
    },
    {
        title: "Cost-Efficiency:",
        message: "Reduce overhead expenses and administrative burdens while maintaining the highest quality of service.",
        color: "green"
    },
    {
        title: "24/7 Availability:",
        message: "We offer extended hours, ensuring that your patients always have a line of communication when they need it.",
        color: "green"
    },
    {
        title: "HIPAA Compliant:",
        message: "Patient data security is paramount. Our services uphold the strictest standards for data confidentiality and security.",
        color: "green"
    },
]

export const VdrBenefitsList = [
    {
        title: "Efficiency:",
        message: "Our receptionists handle patient scheduling, follow-ups, and confirmations, keeping your appointments seamless.",
        color: "green"
    },
    {
        title: "Cost-effective:",
        message: "Eliminate overheads associated with in-house staff, such as space and additional resources.",
        color: "green"
    },
    {
        title: "Flexibility:",
        message: "Opt for full-time, part-time, or seasonal support tailored to your practice's needs.",
        color: "green"
    },
    {
        title: "Enhanced patient satisfaction:",
        message: " Prompt response times and professional service make all the difference.",
        color: "green"
    },
    {
        title: "Data Management:",
        message: "Organize patient records, ensuring they're updated and easily retrievable.",
        color: "green"
    }
]

export const vmrDuties = [
    {
        img: dutyImg1,
        title: "Appointment Management:",
        message: "Our team ensures meticulous scheduling, timely reminders, and efficient follow-ups, streamlining your practice operations."
    },
    {
        img: dutyImg2,
        title: "Patient Communication:",
        message: "From addressing inquiries to handling feedback, our virtual receptionists ensure that every patient interaction is handled with utmost care."
    },
    {
        img: dutyImg3,
        title: "Billing and Payment Inquiries:",
        message: "We simplify billing queries, helping patients understand their invoices and facilitating prompt payments."
    },
    {
        img: dutyImg4,
        title: "Record Maintenance:",
        message: "With real-time updates, we ensure patient records remain accurate and current."
    }
]

export const virtualDentalRDuties = [
    {
        img: dutyImg1,
        title: "Appointment Scheduling:",
        message: "Efficiently managing bookings, rescheduling, and cancellations.",
    },
    {
        img: dutyImg2,
        title: "Patient Communication:",
        message: "Sending appointment reminders, addressing queries, and ensuring effective patient outreach."
    },
    {
        img: dutyImg3,
        title: "Billing & Insurance:",
        message: "Assisting with insurance verifications and handling billing inquiries."
    },
    {
        img: dutyImg4,
        title: "Record Maintenance:",
        message: "Organizing and updating electronic patient records."
    },
    {
        img: dutyImg4,
        title: "Referral Management:",
        message: "Handling and tracking patient referrals to specialists or other healthcare providers."
    }
]

export const VMRWhyChoooseUs = [
    {
        title: "Highly Trained Professionals:",
        message: "Each of our receptionists undergoes rigorous in-house training to handle the unique demands of various medical practices. Additionally, they have completed certification courses on Udemy and Coursera, ensuring they are well-versed in best practices for customer service and professional reception duties. This combination guarantees top-notch service every time."
    },
    {
        title: "Versatile Expertise:",
        message: "While our virtual receptionists have a significant track record with psychiatric and mental health practices, they are versatile and well-prepared to cater to any medical setting, ensuring all communication needs are addressed."
    },
    {
        title: "Secure and Confidential:",
        message: "Using secure platforms, every patient interaction remains confidential and protected."
    },
    {
        title: "Adaptable Integration:",
        message: "We offer seamless integration into your existing systems without any hassle."
    }
]

export const VDRWhyChooseUs = [
    {
        title: "Professional Training:",
        message: "Our team is not only well-versed in dental administration but has also completed certification courses on Udemy and Coursera on Customer Services and being a professional receptionist."
    },
    {
        title: "Adaptable:",
        message: "Our virtual receptionists are adept at using various dental management software, ensuring a smooth integration into your existing systems"
    },
    {
        title: "Secure & Confidential:",
        message: "Patient confidentiality is paramount. We strictly adhere to all privacy standards and regulations."
    },
    {
        title: "Affordable:",
        message: "With HonestTaskers, you receive high-quality service without the high costs associated with traditional hiring."
    }
]

export const VDRFaqs = [
    {
        question: "How quickly can I onboard a Virtual Dental Receptionist?",
        answer: "After signing up, our team will collaborate with you to understand your specific needs. Typically, you can have a Virtual Dental Receptionist ready to assist you within 5-7 business days."
    },
    {
        question: "Will the same receptionist be assigned to my practice or will it change often?",
        answer: "We believe in consistency. Once a Virtual Dental Receptionist is assigned to your practice, they'll continue to work with you unless there's a specific request or need for a change."
    },
    {
        question: "How do you ensure the quality of service provided by the virtual receptionist?",
        answer: "Our receptionists undergo rigorous training and certification. Furthermore, we have periodic quality checks and feedback sessions to ensure the service remains top-notch."
    },
    {
        question: "Can the receptionist handle emergency appointment requests or sudden changes?",
        answer: "Yes, our Virtual Dental Receptionists are trained to handle emergency requests and can adapt to sudden changes, ensuring that your patients receive prompt attention."
    },
    {
        question: "What happens if there's a technical glitch or if I can't reach my receptionist?",
        answer: "We provide 24/7 technical support. In case of any glitches or if you're unable to reach your assigned receptionist, our support team will immediately assist you to ensure continuity."
    },
    {
        question: "Are there any long-term contracts or commitments?",
        answer: "We offer flexible plans tailored to your needs. You can choose short-term or long-term commitments based on your comfort and satisfaction with our service."
    },
    {
        question: "How do you handle time zones? Can the receptionist work in my specific time zone?",
        answer: "Absolutely! Our Virtual Dental Receptionists can work according to your specific time zone, ensuring real-time support for your practice."
    },
    {
        question: "What's the process if I decide to terminate or pause the service?",
        answer: "We aim for hassle-free processes. If you decide to terminate or pause, just notify us in advance (usually 30 days). We'll guide you through the transition and ensure no disruptions to your practice."
    }
]

export const pricingTitle = "We offer a range of tailored packages to cater to diverse practice<br> sizes and requirements. From basic to advanced, our solutions<br> are designed to provide unmatched value and efficiency."

export const getImageStructuralData = (url, caption) => {
    return {
        "@context": "https://schema.org/",
        "@type": "ImageObject",
        "contentUrl": url,
        "license": "https://honesttaskers.com/terms-of-service",
        "acquireLicensePage": "https://honesttaskers.com/privacy-policy",
        "creditText": "HonestTaskers",
        "creator": {
          "@type": "Organization",
          "name": "HonestTaskers"
         },
        "copyrightNotice": "HonestTaskers",
        "caption": caption
    }
}

export const getFAQStructuralData = (items) => {
    return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": items.map(item => {
          return {
          "@type": "Question",
          "name": item.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>"+ item.answer +"</p>"
            }
          }
        })
    }
}

export const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];