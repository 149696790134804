import { Routes, Route} from 'react-router-dom';
import React from 'react';
import { Footer, Header } from '../Components';


export default function AuthRoutes() {

    return (
        <div>
            <Header />
            <div className='main-content'>
                <Routes>
                    {/* <Route exact path='/sign-in' element={<SignInPage />} /> */}
                </Routes>
            </div>
            <Footer />
        </div>
    )
}