import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";

export default function VmBenefitsOfHiring() {

    const BenefitsList = [
        {
            title: "Increase Efficiency:",
            message: "Spend more time with patients while our expert scribes handle the documentation.",
            color: "green"
        },
        {
            title: "Improved Patient Experience:",
            message: "Elevate your patient-care by minimizing administrative distractions.",
            color: "orange"
        },
        {
            title: "Enhanced Accuracy:",
            message: "Benefit from the combined expertise of registered nurses who have scribe certification.",
            color: "red"
        },
        {
            title: "Compliance and Confidentiality:",
            message: "Adherence to the Data Privacy Act and HIPAA ensures unparalleled data protection.",
            color: "red"
        },
    ]

    function BenefitCard({item, index}) {
        return (
            <div className={`b-card ${item.color}`}>
                <div className="title-wrapper">
                    <div className="title">{item.title}</div>
                </div>
                <div className="message">{item.message}</div>
                <div className="num-bg"></div>
                <div className="num">{index < 9 ? `0${index+1}` : `${index+1}`}</div>
            </div>
        )
    }

    return (
        <div className="vm-benefits">
            <div className="inner-bg">
                <div className="container">
                    <div className="content">
                        <h2>Benefits of Hiring</h2>
                        <p>HonestTaskers:</p>
                    </div>
                </div>
                <div className="container slider-container">
                    <div className="benefits-slider">
                        <Media
                            queries={{
                                lg: "(min-width: 1655px)",
                                md: "(min-width: 1360px) and (max-width: 1654px)",
                                sm: "(min-width: 1032px) and (max-width: 1359px)",
                                normal: "(max-width: 1031px)",
                            }}
                        >
                            {(matches) => (
                                <OwlCarousel 
                                    className="owl-theme"
                                    stagePadding={
                                        matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                                    }
                                    margin={
                                        matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                                    }
                                    items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                                    dots
                                    loop
                                    autoplay
                                    rewind
                                    autoplaySpeed={500}
                                    autoplayHoverPause
                                    autoplayTimeout={2000}
                                >
                                    {
                                        BenefitsList.map((item, index) => (
                                            <BenefitCard item={item} key={index} index={index}/>
                                        ))
                                    }
                                </OwlCarousel>
                            )}
                        </Media>
                    </div>
                </div>
                {/* <div className="mobile-slider">
                    <div className="container">
                        <div className="row">
                            {
                                BenefitsList.map((item, index) => (
                                    <div className="col-md-6" key={index}>
                                        <BenefitCard item={item} key={index} index={index}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}