import React from "react";
import './style.scss';
import feature1 from 'src/Assets/Images/VmsHome/Feature1.svg';
import feature2 from 'src/Assets/Images/VmsHome/Feature2.svg';
import feature3 from 'src/Assets/Images/VmsHome/Feature3.svg';
import feature4 from 'src/Assets/Images/VmsHome/Feature4.svg';
import feature5 from 'src/Assets/Images/VmsHome/Feature5.svg';
import feature6 from 'src/Assets/Images/VmsHome/Feature6.svg';

export default function Features() {

    const features = [
        {
            img: feature1,
            title: "All Registered Nurses and Pharmacists:",
            message: "Our scribes are not just any scribes; they're qualified nurses and pharmacists ensuring top-tier documentation."
        },
        {
            img: feature2,
            title: "Certified in Medical Scribing:",
            message: "Every scribe has undergone rigorous training and holds a certification in medical scribing."
        },
        {
            img: feature3,
            title: "Completed over 40 hours of generical scribe training:",
            message: "if specialist training in a specific medical vertical, that our team will provide."
        },
        {
            img: feature4,
            title: "HIPAA Compliant:",
            message: "Prioritizing patient confidentiality."
        },
        {
            img: feature5,
            title: "Real-time Documentation:",
            message: "Accuracy and timeliness, always."
        },
        {
            img: feature6,
            title: "Trained on popular EMR",
            message: "Our virtual medical scribes are proficiently trained in popular EMR systems including Epic Systems Corporation, Cerner Corporation, Allscripts Healthcare Solutions, McKesson Corporation, Meditech, Athenahealth, eClinicalWorks, NextGen Healthcare, GE Healthcare, Greenway Health, and Tebra."
        }
    ]

    function FeatureItem({item}) {

        return (
            <div className="col-md-6">
                <div className="feature-item">
                    <img src={item.img}/>
                    <div className="info">
                        <div className="title">{item.title}</div>
                        <div className="message">{item.message}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="features">
            <div className="container">
                <h2>Features of HonestTaskers’<br></br>Virtual Medical Scribe</h2>
                <div className="row">
                   {
                    features.map((item, index) => (
                        <FeatureItem item={item} key={index}/>
                    ))
                   }
                </div>
            </div>
        </div>
    )
}