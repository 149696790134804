import { combineReducers } from 'redux';

import Home from './Home'
import Contact from './Contact';
import Profile from './Profile';

export default combineReducers({
    Home,
    Contact,
    Profile
})