import React from "react";
import './style.scss';

export default function VmHowItWorks({howItWorkList, title = "How It Works"}) {

    function ItemRow({item, index}) {

        return (
            <div className="col-md-6">
                <div className="item-row">
                    <div className="num-wrapper">{index < 9 ? '0': ''}{index+1}</div>
                    <div className="info">
                        <div className="title">{item.title}</div>
                        <div className="message">{item.message}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="vm-how-it-works">
            <div className="container">
                <h2>{title}</h2>
                <div className="row">
                   {
                    howItWorkList.map((item, index) => (
                        <ItemRow item={item} key={index} index={index}/>
                    ))
                   }
                </div>
            </div>
        </div>
    )
}